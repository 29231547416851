import ResizeObserver from 'resize-observer-polyfill';

function maybePolyfillResizeObserver() {
  if (typeof window.ResizeObserver === 'undefined') {
    window.ResizeObserver = ResizeObserver;
  }
}

function maybePolyfillBlobArrayBuffer() {
  if (!Blob.prototype.arrayBuffer) {
    Blob.prototype.arrayBuffer = function () {
      return new Promise<ArrayBuffer>((resolve) => {
        const fr = new FileReader();
        fr.onload = () => {
          if (fr.result) {
            resolve(fr.result as ArrayBuffer);
          }
        };
        fr.readAsArrayBuffer(this);
      });
    };
  }
}

export function initializePolyfills() {
  if (typeof document !== 'undefined') {
    maybePolyfillResizeObserver();
    maybePolyfillBlobArrayBuffer();
  }
}
