import urlcat from 'urlcat';

export enum Routes {
  home = '/',
  login = '/login',
  signUp = '/signup',
  resetPassword = '/reset-password',
  setPassword = '/set-password',
  verifyEmail = '/verify-email',
  emailVerified = '/email-verified',
  firebaseEmailActionHandler = '/auth-action',
  onboarding = '/onboarding',
  createOrganization = '/create-organization',

  appDashboard = '/',
  appInbox = '/inbox',
  appCsvImport = '/inbox/csv',
  appPacks = '/packs',
  appSettings = '/settings/account',
  appSettingsAccount = '/settings/account',
  appSettingsBrands = '/settings/brands',
  appSettingsBrandEdit = '/settings/brands/:brandId',
  appSettingsTeammates = '/settings/teammates',
  appSettingsCompany = '/settings/company',
  appSettingsCollections = '/settings/collections',
  appSettingsShipments = '/settings/shipments',
  appSettingsPricing = '/settings/pricing',
  appSettingsInvoicing = '/settings/invoicing',
  appSettingsInvoicingBreakdown = '/settings/invoicing/breakdown',
  appSettingsIntegrations = '/settings/integrations',
  appSettingsIntegrationsMarketplace = '/settings/integrations/marketplace',
  appSettingsSalesDemo = '/settings/sales-demo',

  appNewCollection = '/collections/new',
  appNewReturn = '/returns/new',
  // For now, we only support shipment-based claims
  // In the future we might want to extend this to collections as well
  appNewClaim = '/claims/:shipmentId',
  appEditClaim = '/claims/:shipmentId/:claimId',
  appEditCollection = '/collections/:id/edit',
  appEditReturn = '/returns/:id/edit',
  appShipments = '/shipments',
  appNewShipment = '/shipments/new',
  appImportShipments = '/shipments/import',
  appShipmentsToCollect = '/shipments/to-collect',
  appShipmentsOnTheirWay = '/shipments/on-their-way',
  appShipmentsDelivered = '/shipments/delivered',
  appEditShipment = '/shipments/:id/edit',
  appShopifySettings = '/shopify/:canonicalName/settings',
  appShopifyConnect = '/shopify/connect',
  appAPIConnect = '/integrations/api/connect',
  appBigCommerceConnect = '/integrations/bigcommerce/connect',
  appBigCommerceSettings = '/integrations/bigcommerce/:sessionId/settings',
  appLinnworks = '/integrations/linnworks',
  appLinnworksImport = '/integrations/linnworks/:sessionId/import',
  appLinnworksSettings = '/integrations/linnworks/:sessionId/settings',
  appLinnworksConnect = '/integrations/linnworks/connect',
  appSquarespaceSettings = '/integrations/squarespace/:sessionId/settings',
  appSquarespaceConnect = '/integrations/squarespace/connect',
  appWooCommerceSettings = '/integrations/woocommerce/:sessionId/settings',
  appWooCommerceConnect = '/integrations/woocommerce/connect',
  appMagentoSettings = '/integrations/magento/:sessionId/settings',
  appMagentoConnect = '/integrations/magento/connect',
  appMintsoftConnect = '/integrations/mintsoft/connect',
  appVeeqoConnect = '/integrations/veeqo/connect',
  appTracking = '/tracking/:trackingNumber',
  appManageRecurringCollection = '/recurring-collections/manage',
  appManageCollectionLocations = '/locations/manage',
  appEditCollectionLocation = '/locations/:id/edit',
  appNewCollectionLocation = '/locations/new',
  appLocationDashboard = '/locations/:id/:slug',
  appAnalytics = '/analytics',
  appAnalyticsBeta = '/beta/analytics',

  appPackfleetProProfile = '/settings/pro/profile',
  appPackfleetProAppearance = '/settings/pro/appearance',
  appPackfleetProBrands = '/settings/pro/brands',
  appPackfleetProAnalytics = '/settings/pro/analytics',
  appPackfleetProNotificationTemplate = '/settings/pro/templates/:trigger/edit',
  appPackfleetProNotificationBlock = '/settings/pro/blocks/:block/edit',

  appPackfleetProBrandProfile = '/settings/pro/brands/:brandId/profile',
  appPackfleetProBrandAppearance = '/settings/pro/brands/:brandId/appearance',
  appPackfleetProBrandAnalytics = '/settings/pro/brands/:brandId/analytics',
  appPackfleetProBrandNotificationTemplate = '/settings/pro/brands/:brandId/templates/:trigger/edit',
  appPackfleetProBrandNotificationBlock = '/settings/pro/brands/:brandId/blocks/:block/edit',

  apiLogin = '/internal-api/auth/session-login',
  apiLogout = '/internal-api/auth/session-logout',
  apiInternalAPIOAuthAuthorize = '/internal-api/oauth/authorize',
  apiInternalAPIOAuthConfirmAuthorize = '/internal-api/oauth/authorize/confirm',
  apiGenerateCSV = '/internal-api/routing/generate-csv',
  apiGenerateOptimizedCSV = '/internal-api/routing/generate-csv/optimized',
  apiInstallShopify = '/internal-api/shopify/install',
  apiInstallSquarespace = '/internal-api/squarespace/auth/initiate',
  apiGenerateLabels = '/internal-api/labels/generate',
  apiGenerateExternalLabels = '/internal-api/labels/generate-external',
  apiPreviewEmail = '/internal-api/email/preview',
  apiGenerateLabelsAndPackingSlips = '/internal-api/labels/generate-labels-and-packing-slips',

  apiAdminLoginAs = '/internal-api/admin/login-as',
  apiAdminLogoutAs = '/internal-api/admin/logout-as',
  apiAdminSendVerificationEmail = '/internal-api/admin/send-email-verification',
}

export const PUBLIC_APP_DOMAIN =
  process.env.NEXT_PUBLIC_BASE_URL ??
  process.env.NEXT_PUBLIC_VERCEL_URL ??
  'localhost:3000';

export function route(
  path: Routes,
  params: { [key: string]: string | string[] | undefined } = {},
) {
  return urlcat('', path, params);
}

export function constructURL(
  domain: string,
  path: string,
  params?: URLSearchParams,
) {
  let protocol = 'https';
  if (domain.startsWith('localhost')) {
    protocol = 'http';
  }
  if (params == null) {
    return `${protocol}://${domain}/${path}`;
  }
  return `${protocol}://${domain}/${path}?${params.toString()}`;
}

export function generateTrackingEmbedCode(organizationCanonicalName: string) {
  return [
    `<div data-organization="${organizationCanonicalName}" id="packfleet-pro-embed" style="max-width:370px;width:100%;min-height:320px;" class="packfleet-pro-embed"></div>`,
    `<div data-organization="${organizationCanonicalName}" id="packfleet-pro-order-items" class="packfleet-pro-embed"></div>`,
    `<script type="module" src="https://packfleet.com/embed.js"></script>`,
  ].join('');
}
