// src/index.ts
import {
  ApolloLink
} from "@apollo/client";
import * as uuid from "uuid";
var ApolloLinkRequestId = class extends ApolloLink {
  request(operation, forward) {
    const requestId = uuid.v4();
    operation.setContext(({ headers }) => ({
      headers: {
        "X-Request-Id": requestId,
        ...headers
      }
    }));
    return forward(operation);
  }
};
export {
  ApolloLinkRequestId
};
