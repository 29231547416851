import {
  getAuth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { axiosInstance } from '../axios';
import { Routes } from '../routes';

export async function login(email: string, password: string) {
  const auth = getAuth();
  const result = await signInWithEmailAndPassword(auth, email, password);
  if (result.user.emailVerified) {
    // Only log user in if email is verified
    const token = await result.user.getIdToken();
    await axiosInstance.post(Routes.apiLogin, {
      idToken: token,
    });
  }

  return result;
}

export async function logout() {
  const auth = getAuth();
  await signOut(auth);
  return await axiosInstance.post(Routes.apiLogout, {});
}

export async function loginAs(userId: string) {
  const auth = getAuth();
  const loginAs = await axiosInstance.post(Routes.apiAdminLoginAs, {
    userId,
  });
  const token = loginAs.data.token;
  const result = await signInWithCustomToken(auth, token);
  const idToken = await result.user.getIdToken();
  await axiosInstance.post(Routes.apiLogin, {
    idToken,
  });
}

export async function logoutAs() {
  const auth = getAuth();
  const loginAs = await axiosInstance.post(Routes.apiAdminLogoutAs);
  const token = loginAs.data.token;
  const result = await signInWithCustomToken(auth, token);
  const idToken = await result.user.getIdToken();
  await axiosInstance.post(Routes.apiLogin, {
    idToken,
  });
}

export async function sendVerificationEmail(email: string) {
  return await axiosInstance.post(Routes.apiAdminSendVerificationEmail, {
    email,
  });
}
