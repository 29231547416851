import { useEffect } from 'react';

export const useAutoRefresh = (
  interval: number = 16 * 60 * 60 * 1000, // 16 hours
) => {
  // Add jitter to interval
  const intervalWithJitter = interval + Math.random() * 1000 * 60 * 60; // 1 hour

  useEffect(() => {
    const intervalId = setInterval(() => {
      window.location.reload();
    }, intervalWithJitter);
    return () => clearInterval(intervalId);
  }, [intervalWithJitter]);
};
