import { cn } from '@/lib/utils';
import React, { createContext, ReactNode, RefObject, useContext } from 'react';

export const ScrollViewContext = createContext<{
  ref: RefObject<HTMLDivElement>;
}>({
  ref: { current: null },
});

export const useScrollView = () => useContext(ScrollViewContext);

export const ScrollView = ({
  children,
  className,
}: { children?: ReactNode; className?: string }) => {
  const scrollViewRef = React.useRef<HTMLDivElement>(null);

  return (
    <ScrollViewContext.Provider
      value={{
        ref: scrollViewRef,
      }}
    >
      <div
        ref={scrollViewRef}
        className={cn(
          'h-screen w-full overflow-y-auto overflow-x-hidden',
          className,
        )}
      >
        {children}
      </div>
    </ScrollViewContext.Provider>
  );
};
