import Image from 'next/image';
import React from 'react';

import { Link } from '@/components/link/Link';
import { cn } from '@/lib/utils';
import { Routes, route } from '@/utilities/routes';
import { ScrollView } from '../scroll-view/ScrollView';

export type Props = {
  className?: string;
  variant?: 'default' | 'no-sidebar' | 'fit-content';
} & React.HTMLAttributes<HTMLDivElement>;

export const Container = ({
  className,
  children,
  variant = 'default',
  ...props
}: Props) => {
  return (
    <ScrollView
      {...props}
      className={cn(
        'rounded-[0.5rem] border border-border/50 bg-background shadow-lg w-full h-[calc(100vh-24px-var(--impersonated-by-offset-y))] mt-3 overflow-auto mr-3 p-4',
        variant === 'no-sidebar' ? 'ml-3' : 'ml-0',
        variant === 'fit-content'
          ? 'w-auto h-auto mb-6 p-0 sm:min-w-[600px]'
          : undefined,
        className,
      )}
    >
      <>
        {variant === 'no-sidebar' ? (
          <div className="w-100 flex justify-center pt-4 pb-6 md:pt-10 md:pb-8">
            <Link href={route(Routes.home)}>
              <Image
                src="/packfleet-brand.svg"
                alt="Packfleet logo"
                height={31}
                width={116}
              />
            </Link>
          </div>
        ) : null}
        {children}
      </>
    </ScrollView>
  );
};

export default Container;
