import { Router } from 'next/router';
import NProgress from 'nprogress';

export function initializeProgressBar() {
  NProgress.configure({
    speed: 500,
    trickleSpeed: 100,
  });
  Router.events.on('routeChangeStart', () => NProgress.start());
  Router.events.on('routeChangeComplete', () => NProgress.done());
  Router.events.on('routeChangeError', () => NProgress.done());
}
