import { Reference } from '@apollo/client';
import { PageInfoFragment } from '../../generated/graphql';

export const mergePaginatedNodes = (
  existing: ListNodesPayloadRef | undefined,
  incoming: ListNodesPayloadRef,
) => {
  if (!existing) {
    return incoming;
  }
  const filtered: ListNodesPayloadRef['edges'] = [];
  const seen = new Set<string>();
  const allEdges = [...existing.edges, ...incoming.edges];

  allEdges.forEach((pr) => {
    if (seen.has(pr.node.__ref)) {
      return;
    }
    seen.add(pr.node.__ref);
    filtered.push(pr);
  });
  return {
    ...incoming,
    edges: filtered,
  };
};

export type ListNodesPayloadRef = {
  pageInfo: PageInfoFragment;
  edges: { cursor: string; node: Reference }[];
};
