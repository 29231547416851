import { Temporal } from '@js-temporal/polyfill';
import {
  formatPlainDate,
  formatPlainTime,
  isToday,
  isTomorrow,
  todayLocal,
} from '@packfleet/datetime';
import { Locale } from 'date-fns';
import { setDefaultOptions } from 'date-fns';
import * as Locales from 'date-fns/locale';
import { DayOfWeek } from '../generated/graphql';

export function initializeDateFns() {
  setDefaultOptions({
    locale: getDateFnsLocale(),
  });
}

export function getDayOfWeek(d: Date): DayOfWeek {
  return DAYS_OF_WEEK[d.getDay()];
}

export const DAYS_OF_WEEK = [
  DayOfWeek.Sunday,
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
];

export const HUMAN_ORDERED_DAYS_OF_WEEK = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday,
];

export function formatDate(d: Temporal.PlainDate): string {
  return formatPlainDate(d);
}

export function formatTime(d: Temporal.PlainTime): string {
  return formatPlainTime(d);
}

export function formatFriendlyDate(date: Temporal.PlainDate, timezone: string) {
  if (isToday(date, timezone)) {
    return 'Today';
  } else if (isTomorrow(date, timezone)) {
    return 'Tomorrow';
  } else if (todayLocal(timezone).year === date.year) {
    return formatIntlDate(date, timezone, false);
  }
  return formatIntlDate(date, timezone, true);
}

export function formatIntlDate(
  date: Temporal.PlainDate,
  timezone: string,
  showYear = false,
) {
  return date.toZonedDateTime(timezone).toLocaleString('default', {
    weekday: 'short',
    month: 'long',
    year: showYear ? 'numeric' : undefined,
    day: 'numeric',
  });
}

export function getDateFnsLocale(localeString?: string): Locale {
  // If not included in the function arguments default to the browsers locale
  localeString = localeString ?? Intl.NumberFormat().resolvedOptions().locale;

  const locale = localeString.substring(0, 2);
  const region = localeString.substring(3, 5);

  return (
    Locales[(locale + region) as keyof typeof Locales] ??
    Locales[locale as keyof typeof Locales] ??
    Locales.enGB
  );
}
