// src/AnalyticsProvider.tsx
import { PostHogProvider } from "posthog-js/react";
import React, { useContext, useEffect, useState } from "react";

// src/analytics.ts
import posthog from "posthog-js";
var EventName = /* @__PURE__ */ ((EventName2) => {
  EventName2["clickedCollectionDate"] = "clicked collection date";
  EventName2["clickedAdvanceCollectionHint"] = "clicked advance collection hint";
  EventName2["sawRecipientTimeWindows"] = "saw recipient time windows";
  EventName2["sawTimeWindowOptions"] = "saw time window options";
  EventName2["sawTrustSignalsExperiment"] = "saw trust signals experiment";
  EventName2["sawUrgencyExperiment"] = "saw urgency experiment";
  EventName2["clickedTimeWindowsCTA"] = "clicked time windows CTA";
  EventName2["clickedTimeWindow"] = "clicked time window";
  EventName2["clickedPay"] = "clicked pay";
  EventName2["clickedWeekendDelivery"] = "clicked weekend delivery";
  EventName2["sawSuperEcoDelivery"] = "saw super eco delivery";
  EventName2["clickedSuperEcoDelivery"] = "clicked super eco delivery";
  EventName2["confirmedSuperEcoDelivery"] = "confirmed super eco delivery";
  EventName2["sawMerchantReferral25OffBanner"] = "saw merchant referral 25% off banner";
  EventName2["clickedMerchantReferral25OffBanner"] = "clicked merchant referral 25% off banner";
  EventName2["closedMerchantReferral25OffBanner"] = "closed merchant referral 25% off banner";
  EventName2["clickedMerchantReferral25OffSidebarButton"] = "clicked merchant referral 25% off sidebar button";
  EventName2["dismissedBroadcastMessage"] = "dismissed broadcast message";
  EventName2["clickedBroadcastMessage"] = "clicked broadcast message";
  EventName2["clickedTimeWindowGroupTab"] = "clicked time window group tab";
  return EventName2;
})(EventName || {});
function initAnalytics({
  apiKey,
  onLoaded,
  autocapture
}) {
  posthog.init(apiKey, {
    api_host: "https://app.posthog.com",
    persistence: "memory",
    capture_pageview: false,
    disable_session_recording: true,
    autocapture,
    loaded: (client) => {
      if (process.env.NODE_ENV === "development")
        client.opt_out_capturing();
      if (onLoaded) {
        onLoaded({
          track: (eventName, context) => track(client, eventName, context),
          setDefaultEventContext: (context) => setDefaultEventContext(client, context),
          pageView: (context) => pageView(client, context)
        }, client);
      }
    }
  });
}
function track(instance, eventName, context = {}) {
  instance.capture(eventName, {
    ...context
  });
}
function setDefaultEventContext(instance, context = {}) {
  instance.register({
    ...context
  });
}
function pageView(instance, context = {}) {
  instance.capture("$pageview", context);
}

// src/AnalyticsProvider.tsx
var AnalyticsContext = React.createContext({
  track: () => void 0,
  pageView: () => void 0,
  setDefaultEventContext: () => void 0
});
var useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  return context;
};
var AnalyticsProvider = ({
  children,
  apiKey,
  autocapture
}) => {
  const [instance, setInstance] = useState(null);
  const [client, setClient] = useState(void 0);
  useEffect(() => {
    initAnalytics({
      apiKey,
      onLoaded: (instance2, client2) => {
        setInstance(instance2);
        setClient(client2);
      },
      autocapture
    });
  }, []);
  return /* @__PURE__ */ React.createElement(AnalyticsContext.Provider, {
    value: instance ? {
      track: instance.track,
      pageView: instance.pageView,
      setDefaultEventContext: instance.setDefaultEventContext
    } : null
  }, client ? /* @__PURE__ */ React.createElement(PostHogProvider, {
    client
  }, children) : children);
};

// src/PageView.tsx
import React2, { useEffect as useEffect2 } from "react";
var _AnalyticsContext = React2.createContext({
  loaded: false
});
var PageView = ({
  children,
  context = {},
  path
}) => {
  const analytics = useAnalytics();
  useEffect2(() => {
    if (analytics) {
      analytics.setDefaultEventContext(context);
      analytics.pageView(context);
    }
  }, [analytics, path]);
  return /* @__PURE__ */ React2.createElement(React2.Fragment, null, children);
};

// src/MockAnalyticsProvider.tsx
import React3 from "react";
var MockAnalyticsProvider = ({
  children,
  track: track2 = jest.fn(),
  setDefaultEventContext: setDefaultEventContext2 = jest.fn(),
  pageView: pageView2 = jest.fn()
}) => /* @__PURE__ */ React3.createElement(AnalyticsContext.Provider, {
  value: { track: track2, setDefaultEventContext: setDefaultEventContext2, pageView: pageView2 }
}, children);

// src/flags.ts
import { useFeatureFlagEnabled } from "posthog-js/react";
var createUseFeatureFlag = () => (flagName) => useFeatureFlagEnabled(flagName);
export {
  AnalyticsContext,
  AnalyticsProvider,
  EventName,
  MockAnalyticsProvider,
  PageView,
  createUseFeatureFlag,
  initAnalytics,
  pageView,
  setDefaultEventContext,
  useAnalytics
};
