import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { ApolloLinkRequestId } from '@packfleet/apollo-link-request-id';
import { SentryLink } from 'apollo-link-sentry';
import merge from 'deepmerge';
import isEqual from 'lodash.isequal';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { logout } from '../utilities/request/auth';
import { Routes, route } from '../utilities/routes';
import { apolloConfig } from './config';
import { errorLink, logoutLink } from './error';

export let apolloClient: ApolloClient<any>;

export const API_BASE_URL = '/internal-api';
export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

export function initializeApollo(
  baseURL: string,
  // biome-ignore lint/complexity/noBannedTypes: Hangover from ESLint migration
  initialState: Object = {},
  // biome-ignore lint/complexity/noBannedTypes: Hangover from ESLint migration
  headers: Object = {},
  onLogout: () => void = () => undefined,
) {
  const _apolloClient =
    apolloClient ?? createApolloClient(baseURL, headers, onLogout);

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Deep-merge the state so we keep all the objects
    const result = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore(result);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }

  // Create the Apollo Client once in the client
  apolloClient = _apolloClient;
  return apolloClient;
}

function createApolloClient(
  baseURL: string,
  headers = {},
  onLogout: () => void,
): ApolloClient<any> {
  const link = ApolloLink.from([
    new ApolloLinkRequestId(),
    errorLink,
    logoutLink(onLogout),
    new SentryLink({
      attachBreadcrumbs: {
        includeQuery: true,
        includeVariables: true,
        includeError: true,
        includeFetchResult: true,
      },
    }),
    new HttpLink({ uri: baseURL, headers: headers }),
  ]);
  return new ApolloClient(apolloConfig(link));
}

export function addApolloState(client: ApolloClient<any>, pageProps: any) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: any) {
  const router = useRouter();
  let state: any;
  if (pageProps) {
    state = pageProps[APOLLO_STATE_PROP_NAME];
  }

  const handleLogout = async () => {
    await logout();

    // Nothing to be done if the user is already on the login page
    if (router.pathname === Routes.login) return;

    // hard reload so entire app is re-loaded and tokens reset
    window.location.href = route(Routes.login, { redirect_uri: router.asPath });
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies: handleLogout
  return useMemo(
    () => initializeApollo(`${API_BASE_URL}/graphql`, state, {}, handleLogout),
    [state],
  );
}
