import { ReusableComponentProps } from '@packfleet/ui';
import cs from 'classnames';
import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React, { ReactNode } from 'react';

type Props = ReusableComponentProps &
  LinkProps & {
    target?: string;
    children?: ReactNode;
  };

export const Link = ({
  target,
  className,
  style,
  href,
  children,
  unstyled,
  ...props
}: Props) => {
  const router = useRouter();
  let classNames = className;
  if (!unstyled) {
    classNames = cs(classNames, 'text-primary underline hover:no-underline');
  }

  let pathname = typeof href === 'object' ? href.pathname : href;
  let paramsFromHref = {};

  if (typeof pathname === 'string') {
    const [baseHref, params] = pathname.split('?');
    paramsFromHref = params
      ? Object.fromEntries(new URLSearchParams('?' + params).entries())
      : {};
    pathname = baseHref;
  }

  const query =
    typeof href === 'object' && typeof href.query === 'object'
      ? href.query
      : {};

  // We want to preserve a subset of query params when navigating
  // between pages. The main use case for this is being able to
  // interact with Shopify's app bridge, which requires the "host" param
  const embedded = typeof window !== 'undefined' && window.self !== window.top;
  const currentQuery = embedded ? router.query : {};

  return (
    <NextLink
      href={{
        pathname,
        query: {
          ...currentQuery,
          ...paramsFromHref,
          ...query,
        },
      }}
      passHref={true}
      className={classNames}
      style={style}
      target={target}
      {...props}
    >
      {children}
    </NextLink>
  );
};

export default Link;
