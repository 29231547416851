import dynamic from 'next/dynamic';
import { ReactNode } from 'react';
import { IntercomProviderProps } from 'react-use-intercom';

const _IntercomProvider = dynamic<IntercomProviderProps>(
  () =>
    import('react-use-intercom').then((a) => {
      return a.IntercomProvider;
    }),
  { ssr: false },
);
const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? '';

type Props = { children?: ReactNode };

export const IntercomProvider = ({ children }: Props) => {
  return (
    <_IntercomProvider appId={INTERCOM_APP_ID}>{children}</_IntercomProvider>
  );
};

export default IntercomProvider;
