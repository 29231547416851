import Link from 'next/link';
import * as React from 'react';
import { Button, type ButtonProps } from './button';

// Custom LinkButton
const LinkButton = ({
  children,
  href,
  target,
  ...props
}: ButtonProps & { href: string; target?: string }) => {
  return (
    <Button asChild {...props}>
      <Link href={href} target={target}>
        {children}
      </Link>
    </Button>
  );
};

export { LinkButton };
